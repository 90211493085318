/*
 * @Author       : JiangChao
 * @Date         : 2024-08-19 00:08:08
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-03-12 20:27:00
 * @Description  : 主入口js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router/router.index";
import store from "./store";
import config from "@/env.config.js";
import "@/utils/filterPipe.js";
import "@/plugin/elementUi.js";
import i18n from "./locales";
import './style/main.css'; // 引用 CSS 文件

import { httpAction, getAction, postAction } from "@/utils/request.js";

// vue-cookies配置
import cookies from "vue-cookies";
if (cookies) {
  Vue.prototype.$cookies = cookies;
  // console.log("Cookies have been added to Vue prototype.");
} else {
  // console.error("Cookies are undefined!");
}

// import "@/utils/resize.js";

import animated from "animate.css";
Vue.use(animated);

//将工具类添加到Vue静态方法方便调用
Vue.prototype.$CONFIG = config;
Vue.config.productionTip = false;
Vue.prototype.$http = {
  httpAction,
  getAction,
  postAction,
};


Vue.prototype.goPages = function(page, query) {
  console.log("router", router.push);
  router.push({
    path: page,
    query: query,
  });
};

// 设置初始语言
if (localStorage.getItem("selectedLanguage")) {
  store.dispatch(
    "updateSelectedLanguage",
    localStorage.getItem("selectedLanguage")
  );
}

// 设置初始时区
if (localStorage.getItem("timeZoneValue")) {
  store.dispatch(
    "updateSelectedTimeZone",
    localStorage.getItem("timeZoneValue")
  );
}

// 设置温度类型
if (localStorage.getItem("selectedTempType")) {
  store.dispatch(
    "updateSelectedTempType",
    localStorage.getItem("selectedTempType")
  );
}


// 设置菜单选项
if (localStorage.getItem("selectedMenuItem")) {
  store.dispatch(
    "updateSelectedMenuItem",
    localStorage.getItem("selectedMenuItem")
  );
}

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
