/*
 * @Author       : JiangChao
 * @Date         : 2024-08-23 15:12:16
 * @LastEditors  : JiangChao
 * @LastEditTime : 2025-04-02 14:46:14
 * @Description  :
 */
import Vue from "vue";
import moment from "moment";
import i18n from "../locales/index";

// 定义全局过滤器
Vue.filter("formatDateWithTimeZone", function(
  value,
  timezoneOffsetMinutes,
  isZh
) {
  // 如果值为空或为0，返回 "-"
  if (!value || value === 0) return "-";

  // 将分钟转换为小时
  const timezoneOffsetHours = timezoneOffsetMinutes / 60;

  // 将时间戳转换为 Moment 对象
  const momentDate = moment.utc(value * 1000); // 乘以1000是因为 Moment.js 使用毫秒为单位

  // 应用时区偏移
  const dateInSelectedTimeZone = momentDate
    .clone()
    .utcOffset(timezoneOffsetHours);

  // 根据 isChina 字段选择不同的时间格式
  let formatString = isZh == "zh" ? "YYYY-MM-DD HH:mm" : "DD-MM-YYYY HH:mm";

  // 格式化时间
  const formattedDate = dateInSelectedTimeZone.format(formatString);

  return formattedDate;
});
// 定义全局过滤器
Vue.filter("convertTemperature", function(value, unit) {
  if (typeof value !== "number") return "-";

  if (unit === "°C") {
    // 如果单位是摄氏度，直接返回原值
    return value.toFixed(1);
  } else if (unit === "°F") {
    // 如果单位是华氏度，进行转换
    const fahrenheit = (value * 9) / 5 + 32;
    return fahrenheit.toFixed(1);
  } else {
    return "-";
  }
});

Vue.filter("AlarmType", function(type) {
  const types = {
    "0": "Single measurement",
    "1": "MKT",
    "2": "Cumulative time",
  };

  return types[type] || "-";
});

Vue.filter("AlarmSensor", function(value) {
  const values = {
    "1": "Temperature",
    "2": "Humidity",
    "4": "Probe temperature",
    "8": "Probe Humidity",
    "16": "Battery",
    "32": "Light",
    "64": "Shock",
  };

  return values[value] || "-";
});

// Vue.filter('AlarmConditions', function (value) {
//   const values = {
//       '0': '> more than',
//       '1': '< less than'
//   };

//   return values[value] || 'Unknown';
// });

Vue.filter("deviceIdsSeparated", function(array) {
  if (!Array.isArray(array)) {
    return "";
  }
  return array.join(", ");
});

Vue.filter("TransModel", function(value) {
  // console.log("TransModel---value: ", value);
  const values = {
    1: "land",
    2: "sea",
    3: "air",
    4: "railway",
    5: "warehouse",
    6: "port",
    7: "others",
  };
  return i18n.t(values[value]) || "-";
});

Vue.filter("UploadInterval", function(value) {
  if (!value || isNaN(value)) return "-";
  
  const minutes = parseInt(value);
  
  if (minutes < 120) {
    return `${minutes} Minute${minutes !== 1 ? 's' : ''}`;
  } else {
    const hours = Math.round(minutes / 60); // 四舍五入
    return `${hours} Hour${hours !== 1 ? 's' : ''}`;
  }
});

Vue.filter("timeAgo", function(timestamp) {
  const now = new Date().getTime();
  const inputTime = timestamp * 1000;
  const diffTime = now - inputTime;

  const seconds = Math.floor(diffTime / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) return `${days} ${i18n.t("days_ago")}`;
  if (hours > 0) return `${hours} ${i18n.t("hours_ago")}`;
  if (minutes > 0) return `${minutes} ${i18n.t("minutes_ago")}`;
  return i18n.t("just_now"); // "Just now"
});

Vue.filter("ellipsisText", function(text, maxLength = 16) {
  if (!text) return "-"; // 如果文本为空或未定义，返回'-'

  // 如果文本长度小于等于 maxLength，直接返回原文本
  if (text.length <= maxLength) {
    return text;
  }

  // 截断文本并添加省略号，不考虑省略号本身的长度
  return `${text.slice(0, maxLength)}...`;
});

Vue.filter("formattedMethod", function(item) {
  if (item.method === ">") {
    return "later than";
  } else if (item.method === "<") {
    return "earlier than";
  } else if (item.method === "=") {
    return "is";
  } else {
    return item.method;
  }
});

Vue.filter("formattedValue", function(item) {
  const values = {
    "1": "YES",
    "0": "NO",
  };
  const valuesStatus = {
    "0": "Not started",
    "1": "Recording",
    "2": "Delaying",
    "3": "Ended",
    "4": "Stopped",
    "9": "Discard",
  };
  if (item.remark === "3") {
    return values[item.value];
  } else if (item.remark === "2") {
    return valuesStatus[item.value];
  } else {
    return item.value;
  }
});
